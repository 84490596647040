/* General styles */
body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

/* Header styles */
h1 {
    text-align: center;
    background-color: #6200ea;
    color: white;
    padding: 15px;
    margin: 0;
}

h2 {
    text-align: center;
    background-color: #6200ea;
    color: white;
    padding: 5px;
    margin: 0;
}

h3 {
    text-align: center;
    background-color: #6200ea;
    color: white;
    padding: 5px;
    margin: 0;
}

<style >
.larger {
    font-size: 16px;
}

/* Tables */
table {
    width: 100%; /* Ensure the table takes full width */
    font-size: 14px;
    max-width: none; /* Remove the max-width constraint */
    display: table; /* Use the default table display */
    margin: 0 auto; /* Center the table horizontally if needed */
    border-collapse: collapse;
    background: white;
    border-radius: 10px;
    overflow-x: auto; /* Allow horizontal scrolling if necessary */
}

    table th,
    table td {
        border: 2px solid #ddd;
        padding: 10px;
        text-align: center;
        font-size: 14px;
    }

    table th {
        background-color: #6200ea;
        color: white;
        font-weight: bold;
        font-size: 16px;
    }

    table tr:nth-child(even) {
        background-color: #f9f9f9;
    }   

.cell-content {
    display: inline-flex;
    align-items: center; /* centers the number and icon vertically */
}

.number {
    margin-right: 5px; /* spacing between number and arrow */
}

/* Icons */
.icon {
    width: 16px;
    height: 16px;
}

.icon--up {
    fill: green; /* Grön uppåtpil */
}

.icon--down {
    fill: red; /* Röd nedåtpil */
}

.success-message {
    color: green;
    font-weight: bold;
    font-size: 1.2em;
}

/* Buttons */
button {
    background-color: #6200ea;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px auto;
    display: block;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%; /* Make buttons full-width on mobile */
}

    button:hover {
        background-color: #3700b3;
    }
/* Hamburger CSS */
.hamburger-menu {
    position: relative;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    cursor: pointer;
}

.bar {
    background-color: #fff;
    height: 3px;
    width: 100%;
    border-radius: 2px;
    transition: all 0.3s ease;
}

.hamburger-icon.open .bar:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
}

.hamburger-icon.open .bar:nth-child(2) {
    opacity: 0;
}

.hamburger-icon.open .bar:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
}

.menu {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    background-color: #333;
    color: #fff;
    width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 15px; /* Equal spacing between items */
}

.menu-item {
    position: relative; /* Required for dropdown positioning */
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.3s ease;
    padding-left: 10px;
}

.menu-item2 {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: 0000ff;
}

 .menu-item:hover {
    background-color: #555;
    }

.dropdown {
    position: relative;
}

.dropdown-content {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 0; /* Align with the parent menu item */
    left: 25%; /* Move submenu slightly to the right */
    background-color: #fff;
    color: #000;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dropdown:hover .dropdown-content {
    display: flex;
}

.dropdown-link {
    padding: 10px 20px;
    text-decoration: none;
    color: #000;
    cursor: pointer;
}

    .dropdown-link:hover {
        background-color: #f0f0f0;
    }

    .dropdown-link.disabled {
        color: gray;
        cursor: not-allowed;
    }

.auth-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 10px;
    font-weight: bold;
}


/* Popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 999;
}

.popup-content {
    padding: 20px;
    color: #333;
    text-align: center;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff; /* White background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 80%;
    max-width: 400px;
}

.popup-header {
    background-color: #6200ea; /* Purple background */
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.popup-footer {
    background-color: #6200ea;
    color: white;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

    /* Close button style */
    .popup-footer button {
        background: none;
        color: white;
        border: none;
        font-size: 16px;
        cursor: pointer;
    }

/* Form inputs */
form label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
}

form select,
form input[type="date"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
}

form button {
    width: 100%;
}

.login-container {
    width: 80%;
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

    .login-container form div {
        margin-bottom: 15px;
    }

    .login-container label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
    }

    .login-container input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .login-container button {
        width: 100%;
        padding: 10px;
        background-color: #6200ea;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
    }

        .login-container button:hover {
            background-color: #4b00b5;
        }

.faq-container {
    width: 80%;
    max-width: 400px;
    margin: 30px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.faq-card {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
}

/* Registration prompt styling */
.register-prompt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    font-size: 14px;
}

.register-button {
    background-color: #ffcc00;
    color: #000;
    padding: 6px 12px;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
}

    .register-button:hover {
        background-color: #e6b800;
    }


/* Responsive design */
@media (max-width: 600px) {
    table th,
    table td {
        font-size: 14px;
        padding: 8px;
    }
    .hamburger {
        display: block; /* Make the hamburger button visible on small screens */
    }

    .faq-card {
        max-width: 95%; /* Slightly larger width for smaller screens */
        padding: 12px; /* Reduce padding for smaller screens */
    }

    .nav-links {
        display: none; /* Hide the navigation links by default */
        flex-direction: column;
        background: #333;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        padding: 10px 0;
        z-index: 1000;
    }

        .nav-links.visible {
            display: flex; /* Show the links when the menu is toggled */
        }

        .nav-links a {
            padding: 10px;
            text-align: center;
            color: #fff;
            text-decoration: none;
            border-bottom: 1px solid #444;
        }

        button {
            width: 90%;
        }
}
